import { TourProvider } from '@reactour/tour'
import { PropsWithChildren } from 'react'

import { SegmentEvents, useAnalytics } from 'modules/segment'
import { useIsThemeDark } from 'modules/theming/hooks'
import { useUserContext } from 'modules/user'
import { isMobileDevice } from 'utils/deviceDetection'

import { TourNames } from '../constants'
import { getBaseStyles } from './baseStyles'
import { Navigation } from './TourComponents'

export const TourProviderWrapper = ({ children }: PropsWithChildren<{}>) => {
  const { setSettings } = useUserContext()
  const isDark = useIsThemeDark()
  const analytics = useAnalytics()
  if (isMobileDevice()) {
    return <>{children}</>
  }

  return (
    <TourProvider
      steps={[]}
      onClickMask={() => {
        // noop
      }}
      styles={{ ...getBaseStyles(isDark) }}
      disableKeyboardNavigation={true}
      showBadge={false}
      onClickClose={({
        setIsOpen,
        steps,
        currentStep,
        setCurrentStep,
        setMeta,
        setSteps,
        meta,
      }) => {
        if (steps && currentStep !== steps.length - 1) {
          if (meta === TourNames.DOC_EDITOR) {
            analytics?.track(SegmentEvents.DOC_EDITOR_TOUR_CLOSE_CLICKED, {
              step: currentStep,
            })
            setSettings?.({ remove: ['needsEditorOnboarding'] })
          }
          steps && setCurrentStep(steps.length - 1)
        } else {
          // Clean up
          setSteps && setSteps([])
          setMeta && setMeta('')
          setIsOpen(false)
        }
      }}
      showDots={false}
      components={{
        Navigation,
      }}
    >
      {children}
    </TourProvider>
  )
}
