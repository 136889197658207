import { TourProps } from '@reactour/tour'

export const getBaseStyles = (
  isDark: boolean = false
): TourProps['styles'] => ({
  badge: (base) => ({
    ...base,
    background: 'var(--chakra-colors-trueblue-500)',
  }),
  // @ts-ignore
  dot: (base, { current }) => ({
    ...base,
    background: current ? 'var(--chakra-colors-trueblue-500)' : '#ccc',
  }),
  popover: (base) => ({
    ...base,
    borderRadius: 'var(--chakra-radii-lg)',
  }),
  maskArea: (base) => ({
    ...base,
    rx: 5,
  }),
  maskWrapper: (base) => ({
    ...base,
    color: isDark ? 'white' : base.color,
    opacity: isDark ? 0.1 : 0.2,
    pointerEvents: 'none',
  }),
  clickArea: (base) => ({
    ...base,
    pointerEvents: 'none',
  }),
})
