import { i18n } from '@lingui/core'
import { useRef } from 'react'

import { messages as enMessages } from 'translations/en/messages'

export const initI18nEnglish = () => {
  // Initializes the default state of the Lingui global i18n object
  // This is required for I18nProvider to render any children
  // AND
  // for any translation lookups to return the default (english) version
  i18n.loadAndActivate({ locale: 'en', messages: enMessages })
}

export const useLoadEnglishSync = () => {
  const hasLoaded = useRef(false)
  if (!hasLoaded.current) {
    initI18nEnglish()
    hasLoaded.current = true
  }
}
