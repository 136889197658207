import { ButtonProps } from '@chakra-ui/react'
import { useCallback, useMemo, useState } from 'react'

import { LocalePickerButtonAndModal } from 'modules/i18n/components/LocalePickerButtonAndModal'
import { SupportedLocaleKey } from 'modules/i18n/constants'
import { useLinguiLocale } from 'modules/i18n/hooks/useLinguiLocale'
import { useLocalePreview } from 'modules/i18n/hooks/useLocalePreview'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

export const StatefulLocalePickerButtonAndModal = ({
  buttonProps,
  showLabelOnMobile = false,
}: {
  buttonProps?: ButtonProps
  showLabelOnMobile?: boolean
}) => {
  const i18nLocaleKey = useLinguiLocale()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialI18nLocaleKey = useMemo(() => i18nLocaleKey, [])

  // The latest known value of your user setting locale
  const [_lsLocale, setLocalStorageLocale] = useLocalStorage<string | null>(
    USER_SETTINGS_CONSTANTS.gammaLocale,
    null
  )
  const [localeKey, setLocaleKey] = useState(initialI18nLocaleKey)

  useLocalePreview(localeKey)

  const handleLocaleChange = useCallback(
    (locale: SupportedLocaleKey) => {
      // We need to set this so that the useLocalePreview hook can pick it up and apply the language
      setLocaleKey(locale)
      // Sync local storage with the new locale
      setLocalStorageLocale(locale)
    },
    [setLocalStorageLocale]
  )

  return (
    <LocalePickerButtonAndModal
      selectedLocaleKey={i18nLocaleKey}
      onLocaleSelect={handleLocaleChange}
      buttonProps={buttonProps}
      showLabelOnMobile={showLabelOnMobile}
    />
  )
}
