import { config } from 'config'
import {
  SUPPORTED_LOCALE_KEYS,
  SupportedLocaleKey,
} from 'modules/i18n/constants'

export const getCategoryUrl = (slug) => {
  return `/templates/categories/${slug}`
}

export const generateTemplateLandingUrl = ({
  id,
  slug,
  absolute = false,
}: {
  id?: string | null
  slug?: string | null
  absolute?: boolean
}) => {
  if (!id) return ''
  return `${absolute ? config.FRONTEND_URL : ''}/templates/${
    slug ? `${slug}-` : ''
  }${id}`
}

export const getLocalizedMarketingSiteLinks = (locale?: SupportedLocaleKey) => {
  if (locale === 'en' || !locale || !SUPPORTED_LOCALE_KEYS.includes(locale)) {
    return {
      home: '/',
      pricing: '/pricing',
    }
  }
  return {
    home: `/${locale}`,
    pricing: `/${locale}/pricing`,
  }
}
