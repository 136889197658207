import { EventEmitter } from 'utils/EventEmitter'

import { Theme } from '../types'
import { ThemeConfigurationView } from './types'

type ThemeConfigurationEvents = {
  openThemeConfiguration: {
    baseTheme?: Theme
    view?: ThemeConfigurationView
    isNewFork?: boolean
  }
  setThemeConfigurationView: {
    view: ThemeConfigurationView
  }
  closeThemeConfiguration: {}
}

export const eventEmitter = new EventEmitter<ThemeConfigurationEvents>()
