import {
  Box,
  Flex,
  FlexProps,
  Heading,
  Image,
  Link,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react'
import { Global } from '@emotion/react'
import React from 'react'

import { StatefulLocalePickerButtonAndModal } from 'modules/i18n/components/StatefulLocalePickerButtonAndModal'
import { useLinguiLocale } from 'modules/i18n/hooks/useLinguiLocale'
import { getLocalizedMarketingSiteLinks } from 'modules/marketing_pages/utils/url'
import gammaLogoBlack from 'publicImages/gamma-logo-black.svg'

import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../constants'
import prettyGradient from './pretty-gradient.png'

const fancyGradientTextStyle = {
  position: 'relative',
  fontWeight: '500',
  fontFamily: 'p22-mackinac-pro, sans-serif',
  lineHeight: '1.2em',
  letterSpacing: '-0.03em',
  backgroundImage:
    'linear-gradient(50deg, #2b0aff, #ff5b8a 49%, #ff5b8a 53%, #ff5b8a 55%, #fba64b 77%, #f99b52)',
  backgroundClip: 'text',
  color: 'transparent',
}

export const CenteredMessagePage = ({
  children,
  pageProps,
}: {
  children: JSX.Element
  pageProps?: FlexProps
}) => {
  return (
    <Flex
      h="100%"
      w="100vw"
      overflow="hidden"
      display="flex"
      justify="center"
      align="center"
      bg="linen.500"
      bgGradient={`linear(to-b, var(--chakra-colors-linen-500), var(--chakra-colors-linen-600))`}
      position="relative"
      {...pageProps}
    >
      <Global
        styles={{
          body: {
            height: '100%',
          },
        }}
      />
      <Box
        display={{ base: 'none', md: 'block' }}
        position="absolute"
        inset="0"
        bgImage={`url('${prettyGradient.src}')`}
        bgSize="cover"
        zIndex="1"
        transitionProperty="common"
        transitionDuration="2s"
        transitionTimingFunction={'ease-out'}
      />
      <Flex
        flex={1}
        w="100%"
        h="100%"
        overflow="auto"
        align="center"
        justify="center"
        data-centered-message-card-wrapper
      >
        {children}
      </Flex>
    </Flex>
  )
}

export const CenteredMessageHeader = () => {
  const i18nLocaleKey = useLinguiLocale()
  return (
    <Stack direction="row" justify="space-between">
      <Link
        href={getLocalizedMarketingSiteLinks(i18nLocaleKey).home}
        isExternal={true}
      >
        <Image w="100px" src={gammaLogoBlack.src} alt="Gamma logo" />
      </Link>
      <StatefulLocalePickerButtonAndModal
        buttonProps={{
          variant: 'ghost',
          colorScheme: 'blackAlpha',
          color: 'gray.800',
        }}
      />
    </Stack>
  )
}

export const CenteredMessageCard = ({
  lede,
  title,
  children,
  stackProps,
  showFooter = false,
}: {
  lede?: React.ReactNode | string
  title: React.ReactNode
  children: React.ReactNode
  stackProps?: StackProps
  showFooter?: boolean
}) => {
  return (
    <Stack
      bg="white"
      padding={{ base: 6, md: 8 }}
      zIndex="2"
      borderRadius={{ base: 'none', md: 'xl' }}
      shadow={{ base: 'none', md: 'lg' }}
      maxW="100%"
      w={{ base: '100%', md: '500px' }}
      spacing={8}
      m={{ base: 0, md: 8 }}
      minH={{ base: '100%', md: 'auto' }}
      {...stackProps}
    >
      <CenteredMessageHeader />
      {lede &&
        (typeof lede === 'string' ? (
          <Text fontSize="sm" color="gray.500">
            {lede}
          </Text>
        ) : (
          lede
        ))}
      <Heading sx={fancyGradientTextStyle} fontSize={['3xl']}>
        {title}
      </Heading>
      <Box flex={1}>{children}</Box>

      {showFooter && <CenteredMessagePageFooter />}
    </Stack>
  )
}

const CenteredMessagePageFooter = () => (
  <Stack mt={12} align="center">
    <Text
      color="gray.300"
      fontSize="xs"
      sx={{
        a: {
          color: 'gray.400',
          _hover: { textDecoration: 'underline' },
        },
      }}
    >
      <Link href={TERMS_OF_SERVICE_URL}>Terms of Service</Link> &bull;{' '}
      <Link href={PRIVACY_POLICY_URL}>Privacy Policy</Link>
    </Text>
  </Stack>
)
