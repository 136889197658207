import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Link, useToast } from '@chakra-ui/react'
import { slugifyLowercase } from '@gammatech/lib/dist/slugify'
import { Trans, t } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { useCallback, useEffect, useMemo } from 'react'

import { config } from 'config'
import {
  Font,
  FontSortField,
  GetThemesDocument,
  SortDirection,
  ThemeFont,
  useArchiveThemeMutation,
  useGetGlobalFontsQuery,
  useGetThemeLazyQuery,
  useGetThemeQuery,
  useGetWorkspaceFontsQuery,
  useMakeThemeStandardMutation,
  useUnarchiveThemeMutation,
  useUpdateDocThemeMutation,
} from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { stripQueryParamsAndReplaceState } from 'modules/history/useStripQueryParams'
import { ImageType } from 'modules/media'
import { useAppSelector } from 'modules/redux'
import { selectSiteTheme } from 'modules/sites/reducer'
import {
  ARCHIVED_THEME_NAME_REPLACE_REGEX,
  THEMES_DASHBOARD_LINK,
} from 'modules/theming/constants'
import type { FontMap, Theme } from 'modules/theming/types'
import { isThemeDark } from 'modules/theming/utils/colors'
import { selectTheme } from 'modules/tiptap_editor/reducer'
import { useUserContext } from 'modules/user/context'

import { openThemeEditorWithNewFork } from './ThemeConfiguration/openThemeModallUtils'
import { getEmptyTheme } from './utils/emptyTheme'

export const useGetFonts = () => {
  const { currentWorkspace } = useUserContext()
  const { data: globalFontData } = useGetGlobalFontsQuery()
  const { data: workspaceFontData } = useGetWorkspaceFontsQuery({
    variables: {
      workspaceId: currentWorkspace?.id as string,
      sortBy: { field: FontSortField.Name, direction: SortDirection.Asc },
    },
    skip: !currentWorkspace?.id,
  })

  const [
    globalFonts,
    workspaceFonts,
    fontsMap,
    allThemeFonts,
    themeFontsMap,
    globalFontsMap,
  ] = useMemo(() => {
    const global = globalFontData?.fonts || []
    const workspace = workspaceFontData?.fonts || []
    const allFonts = [...global, ...workspace]
    const map: FontMap = allFonts.reduce<{
      [key: string]: Font
    }>((acc, thisFont) => {
      acc[thisFont.id] = thisFont
      return acc
    }, {})

    const fontsForAllThemes: ThemeFont[] = allFonts.map((font) => ({
      id: font.id,
      name: font.name,
      url: font.cssUrl,
    }))
    const allThemeFontsMap: Record<string, ThemeFont> =
      fontsForAllThemes.reduce<{
        [key: string]: ThemeFont
      }>((acc, font) => {
        acc[font.id] = font
        return acc
      }, {})
    const globalMap: FontMap = global.reduce<{
      [key: string]: Font
    }>((acc, thisFont) => {
      acc[thisFont.id] = thisFont
      return acc
    }, {})

    return [
      global,
      workspace,
      map,
      fontsForAllThemes,
      allThemeFontsMap,
      globalMap,
    ]
  }, [globalFontData?.fonts, workspaceFontData?.fonts])

  return {
    globalFonts,
    workspaceFonts,
    fontsMap,
    allThemeFonts,
    themeFontsMap,
    globalFontsMap,
  }
}

export const useUpdateDocThemeWithAccentImages = ({
  docId,
  editor,
}: {
  docId?: string
  editor?: Editor | null
}) => {
  const [updateDocTheme] = useUpdateDocThemeMutation()

  const updateDocThemeWithAccentImages = useCallback(
    async (themeId: string) => {
      if (!docId) {
        return
      }
      return updateDocTheme({
        variables: { id: docId, themeId },
      }).then(({ data }) => {
        if (editor) {
          editor.commands.updateThemeAccentImages(
            data?.updateDoc?.theme?.config.accentBackgrounds
          )
        }
      })
    },
    [docId, editor, updateDocTheme]
  )

  return { updateDocThemeWithAccentImages }
}

export const useIsThemeDark = () => {
  const docTheme = useAppSelector(selectTheme)
  const siteTheme = useAppSelector(selectSiteTheme)
  if (siteTheme) {
    return isThemeDark(siteTheme)
  }
  if (docTheme) {
    return isThemeDark(docTheme)
  }
  return false
}

export const useGetThemeById = (id: string): Theme => {
  const { data } = useGetThemeQuery({
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: 'cache-first',
  })
  return data?.theme || getEmptyTheme()
}

export const useUnarchiveTheme = ({ theme }: { theme: Theme }) => {
  const toast = useToast()
  const [_unarchiveTheme] = useUnarchiveThemeMutation()
  const displayName = theme.name.replace(ARCHIVED_THEME_NAME_REPLACE_REGEX, '')
  const unarchiveTheme = useCallback(() => {
    if (!theme || !theme.id) return
    toast.close('archive-theme-toast')
    _unarchiveTheme({
      variables: { id: theme.id },
      update: (cache, { data }) => {
        if (!data?.unarchiveTheme) return

        cache.writeQuery({
          query: GetThemesDocument,
          variables: {
            workspaceId: theme.workspaceId,
            archived: false,
          },
          data: {
            themes: [data.unarchiveTheme],
          },
        })
      },
      optimisticResponse: {
        unarchiveTheme: {
          __typename: 'Theme',
          ...theme,
          archived: false,
          updatedTime: new Date().toISOString(),
        },
      },
      refetchQueries: ['GetThemes'],
    })
      .then(() => {
        toast({
          id: 'unarchive-theme-toast',
          title: t`Theme ${displayName} has been restored`,
          status: 'success',
          duration: 3000,
          position: 'top',
          isClosable: true,
        })
      })
      .catch((err) => {
        console.error(`Couldn't restore theme ${displayName} error: ${err}`)
      })
  }, [_unarchiveTheme, theme, toast, displayName])

  return unarchiveTheme
}

const ToastDescription = ({
  showLinkToDashboard,
  unarchiveTheme,
}: {
  showLinkToDashboard?: boolean
  unarchiveTheme: () => void
}) => {
  return showLinkToDashboard ? (
    <Box>
      <Trans>
        Go to{' '}
        <Link
          textDecoration="underline"
          isExternal
          href={THEMES_DASHBOARD_LINK}
        >
          theme dashboard <ExternalLinkIcon mx="2px" />
        </Link>{' '}
        or{' '}
        <Link
          textDecoration="underline"
          as={Link}
          variant="link"
          onClick={unarchiveTheme}
        >
          undo
        </Link>
      </Trans>
    </Box>
  ) : (
    <Box>
      <Trans>
        Made a mistake?{' '}
        <Link
          textDecoration="underline"
          as={Link}
          variant="link"
          onClick={unarchiveTheme}
        >
          Restore theme
        </Link>
        .
      </Trans>
    </Box>
  )
}
export const useArchiveTheme = ({
  theme,
  showLinkToDashboard = false,
}: {
  theme: Theme
  showLinkToDashboard?: boolean
}) => {
  const toast = useToast()
  const [_archiveTheme] = useArchiveThemeMutation()
  const unarchiveTheme = useUnarchiveTheme({ theme })
  const displayName = theme.name.replace(ARCHIVED_THEME_NAME_REPLACE_REGEX, '')
  const archiveTheme = useCallback(() => {
    if (!theme || !theme.id) return

    _archiveTheme({
      variables: { id: theme.id },
      update: (cache, { data }) => {
        if (!data?.archiveTheme) return

        cache.writeQuery({
          query: GetThemesDocument,
          variables: {
            workspaceId: theme.workspaceId,
            archived: false,
          },
          data: {
            themes: [data.archiveTheme],
          },
        })
      },
      optimisticResponse: {
        archiveTheme: {
          __typename: 'Theme',
          ...theme,
          archived: true,
          updatedTime: new Date().toISOString(),
        },
      },
      refetchQueries: ['GetThemes'],
    })
      .then(() => {
        toast({
          id: 'archive-theme-toast',
          title: t`Theme ${theme.name} has been archived`,
          description: (
            <ToastDescription
              showLinkToDashboard={showLinkToDashboard}
              unarchiveTheme={unarchiveTheme}
            />
          ),
          status: 'success',
          duration: 3000,
          position: 'top',
          isClosable: true,
        })
      })
      .catch((err) => {
        console.error(`Couldn't archive theme ${displayName} error: ${err}`)
      })
  }, [
    theme,
    _archiveTheme,
    toast,
    showLinkToDashboard,
    unarchiveTheme,
    displayName,
  ])

  return archiveTheme
}

export const useConvertToStandardTheme = (theme: Theme) => {
  const { isGammaOrgUser } = useUserContext()
  const canMakeStandard =
    theme.workspaceId !== null &&
    isGammaOrgUser &&
    config.APPLICATION_ENVIRONMENT !== 'production' &&
    !theme.archived

  const [makeThemeStandard] = useMakeThemeStandardMutation()

  const convertToStandard = useCallback(() => {
    if (!canMakeStandard) return
    const defaultId = slugifyLowercase(theme.name)
    const newId = prompt('Enter new theme ID', defaultId)
    if (!newId) return

    makeThemeStandard({
      variables: { id: theme.id, newId },
      refetchQueries: ['GetThemes'],
    })
  }, [theme, makeThemeStandard, canMakeStandard])

  return { canMakeStandard, convertToStandard }
}

// The special org ID we use for theme image uploads, so that we're not using the gamma workspace
// for these
const THEME_IMAGES_ORG_ID = 'theme_images/standard'

export const useStandardThemeImageBucket = (
  orgId: string | undefined,
  editType: ImageType
) => {
  const { isGammaOrgUser } = useUserContext()

  const enableProdThemeImages = useFeatureFlag('uploadThemeImagesToProd')
  const useStandardThemeBucket =
    isGammaOrgUser && enableProdThemeImages && editType === 'themeBackground'

  if (!useStandardThemeBucket) {
    return {
      orgId,
      templateId: undefined, // Use default
      useStandardThemeBucket: false,
    }
  }

  return {
    orgId: THEME_IMAGES_ORG_ID,
    templateId: config.TRANSLOADIT_THEME_IMAGE_TEMPLATE_ID,
    useStandardThemeBucket: true,
  }
}

export const usePreviewSharedTheme = (themeId: string) => {
  const [getTheme] = useGetThemeLazyQuery()

  useEffect(() => {
    if (!themeId) {
      return
    }

    const loadTheme = async () => {
      const { data, error } = await getTheme({
        variables: {
          id: themeId,
        },
      })
      if (data?.theme) {
        openThemeEditorWithNewFork({ theme: data.theme })
      } else if (error) {
        // remove query param if theme is invalid
        stripQueryParamsAndReplaceState({ queryKeys: ['themeId'] })
      }
    }

    loadTheme()
  }, [getTheme, themeId])
}
