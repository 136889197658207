import { eventEmitter as themeConfigurationEventEmitter } from 'modules/theming/ThemeConfiguration/eventEmitter'
import type { Theme } from 'modules/theming/types'

export const openThemeEditorWithCurrentTheme = ({
  theme,
}: {
  theme: Theme
}) => {
  // If the theme has a workspaceId, it's a custom theme, and we'll edit it directly.
  themeConfigurationEventEmitter.emit('openThemeConfiguration', {
    baseTheme: theme,
    view: 'editor',
  })
}

export const openThemeEditorWithNewFork = ({ theme }: { theme: Theme }) => {
  themeConfigurationEventEmitter.emit('openThemeConfiguration', {
    baseTheme: theme,
    view: 'editor',
    isNewFork: true,
  })
}

export const openThemeModalSplash = () => {
  themeConfigurationEventEmitter.emit('openThemeConfiguration', {
    view: 'splash',
  })
}

export const openThemeModalImport = () => {
  themeConfigurationEventEmitter.emit('openThemeConfiguration', {
    view: 'import',
  })
}
